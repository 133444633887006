"use strict";
exports.__esModule = true;
exports.LOCALE_NAMES = exports.IS_IE11 = exports.MAX_EXPLORER_PAGES = exports.ADMIN_URLS = exports.STRINGS = exports.ADMIN_API = void 0;
exports.ADMIN_API = global.wagtailConfig.ADMIN_API;
exports.STRINGS = global.wagtailConfig.STRINGS;
exports.ADMIN_URLS = global.wagtailConfig.ADMIN_URLS;
// Maximum number of pages to load inside the explorer menu.
exports.MAX_EXPLORER_PAGES = 200;
exports.IS_IE11 = !global.ActiveXObject && 'ActiveXObject' in global;
exports.LOCALE_NAMES = new Map();
/* eslint-disable-next-line camelcase */
global.wagtailConfig.LOCALES.forEach(function (_a) {
    var code = _a.code, display_name = _a.display_name;
    exports.LOCALE_NAMES.set(code, display_name);
});
