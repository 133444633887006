"use strict";
exports.__esModule = true;
exports.get = void 0;
var fetch = global.fetch;
var Headers = global.Headers;
var REQUEST_TIMEOUT = 15000;
var checkStatus = function (response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    var error = new Error(response.statusText);
    throw error;
};
var parseJSON = function (response) { return response.json(); };
// Response timeout cancelling the promise (not the request).
// See https://github.com/github/fetch/issues/175#issuecomment-216791333.
var timeout = function (ms, promise) {
    var race = new Promise(function (resolve, reject) {
        var timeoutId = setTimeout(function () {
            reject(new Error('Response timeout'));
        }, ms);
        promise.then(function (res) {
            clearTimeout(timeoutId);
            resolve(res);
        }, function (err) {
            clearTimeout(timeoutId);
            reject(err);
        });
    });
    return race;
};
/**
 * Wrapper around fetch with sane defaults for behavior in the face of
 * errors.
 */
var request = function (method, url) {
    var options = {
        credentials: 'same-origin',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }),
        method: method
    };
    return timeout(REQUEST_TIMEOUT, fetch(url, options))
        .then(checkStatus)
        .then(parseJSON);
};
var get = function (url) { return request('GET', url); };
exports.get = get;
